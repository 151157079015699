import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import "../styles/Footer.css"
function Footer() {
    const url = 'https://www.linkedin.com/in/öykü-danışık-8128261b5/'
    return (
        <div className="footer">
            <div className="socialMedia">
                <LinkedInIcon onClick={() => window.open(url, '_blank')}   />
            </div>
            <p> &copy; 2023 oykudanisik.com</p>
        </div>
    )
}

export default Footer
