import Proj1 from "../assets/selfservice.PNG";
import Proj2 from "../assets/ventilator.jpeg";
import Proj3 from "../assets/smartplant.jpeg";
import Proj4 from "../assets/todo.jpeg";
import Proj5 from "../assets/puzzle.jpeg";


export const ProjectList = [
  {
    name: "Restaurant Order and Payment System",
    image: Proj1,
    skills: "React, React Native, Go, JavaScript, HTML, CSS, MySQL",
  },
  {
    name: "Ventilator Controller",
    image: Proj2,
    skills: "Node.js, Express.js, MongoDB",
  },
  {
    name: "Smart Plant App",
    image: Proj3,
    skills: "Node.js, Express.js, MongoDB",
  },
  {
    name: "To‑Do App",
    image: Proj4,
    skills: "PHP, HTML, CSS, Javascript",
  },
  {
    name: "Puzzle Game",
    image: Proj5,
    skills: "HTML, CSS, Javascript",
  },
];