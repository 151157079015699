import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import "../styles/Home.css"

function Home() {
  const url = 'https://www.linkedin.com/in/öykü-danışık-8128261b5/'
  return (
    <div className="home">
      <div className="about">
        <h2>Hi! My name is Öykü</h2>
        <div className="prompt">
          <p>Logical and results-driven Front-End Developer dedicated to building
            and optimizing user-focused websites for customers with various
            business objectives. </p>
          <LinkedInIcon onClick={() => window.open(url, '_blank')}  ></LinkedInIcon>
        </div>
      </div>
      <div className="skills"></div>
      <div className="skills">
        <h1> Skills</h1>
        <ol className="list">
          <li className="item">
            <h2> Front-End</h2>
            <span>
              ReactJS, HTML, CSS, React Native, NPM, BootStrap, MaterialUI
            </span>
          </li>
          <li className="item">
            <h2>Back-End</h2>
            <span>
              PHP, NodeJS, ExpressJS, MySQL, MongoDB, AWS S3, MS SQL
            </span>
          </li>
          <li className="item">
            <h2>Languages</h2>
            <span>JavaScript, Java, Python, C, PHP </span>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Home
