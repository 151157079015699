import React from "react";
import { useParams } from "react-router-dom";
import { ProjectList } from "../data/ProjectList";
import GitHubIcon from "@material-ui/icons/GitHub";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "../styles/ProjectDisplay.css";
import { useNavigate } from "react-router-dom";

function ProjectDisplay() {
    const { id } = useParams();
    const project = ProjectList[id];
    const navigate = useNavigate();
    const url = "https://github.com/oykudanisik"
    return (
        <div className="project">
            <div className="projectList">

                <h1>{project.name}</h1>
            </div>
            <img alt={project.image} src={project.image} />
            <p>
                <b>Skills:</b> {project.skills}
            </p>
            <GitHubIcon onClick={() => window.open(url, '_blank')} />
        </div>
    );
}

export default ProjectDisplay;